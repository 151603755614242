import React from 'react';
import './About.css';

function About() {

	return (
		<React.Fragment>
			<section className='intro'>
				<p>Over the past decade I've worked with hundreds of businesses and business owners to create and implement digital and physical solutions to help overcome a variety of obstacles. With these businesses and obstacles ranging from small to large, I've been able to develop and refine a very broad yet related set of creative skills to power the day to day. Flexibility and clear communication help keep it all on track.</p>
				<p>Below is a brief overview of my experiences and abilities.</p>
			</section>
			<section className='cv-container'>
				<article className='cv-column' id='personal'>
					<div className='cv-block'>
						<h2>Cameron<br />McDougall</h2>
						<span>hey@cameronmcdougall.eu</span>
					</div>
				</article>
				<article className='cv-column' id='specs'>
					<div className='cv-block'>
						<h4>Skills</h4>
						<h5>Languages + Frameworks</h5>
						<p>ES6 Javascript <span className='pipe'>|</span> ReactJS <span className='pipe'>|</span> TypeScript <span className='pipe'>|</span> jQuery <span className='pipe'>|</span> WordPress CMS <span className='pipe'>|</span> PHP <span className='pipe'>|</span> HTML5 <span className='pipe'>|</span> CSS3/Sass/SCSS <span className='pipe'>|</span> Dart (Flutter) <span className='pipe'>|</span> Git <span className='pipe'>|</span> NodeJS</p>
						<h5>App Proficiency</h5>
						<p>Visual Studio Code <span className='pipe'>|</span> Azure DevOps <span className='pipe'>|</span> XAMPP/MAMP <span className='pipe'>|</span> InDesign <span className='pipe'>|</span> Photoshop <span className='pipe'>|</span> Illustrator <span className='pipe'>|</span> Adobe XD <span className='pipe'>|</span> Bridge <span className='pipe'>|</span> PowerShell/CMD</p>
						<h5>Visual</h5>
						<p>User Interface (UI) Design <span className='pipe'>|</span> Graphic + Print Design <span className='pipe'>|</span> User Experience (UX) Design <span className='pipe'>|</span> Typography <span className='pipe'>|</span> Branding + Identity <span className='pipe'>|</span> Layout</p>
					</div>
					<div className='cv-block'>
						<h4>Education</h4>
						<h5>City of Glasgow College</h5>
						<p><strong>HND Computer Arts + Design</strong><br />Units studied during this course</p>
							<div className='split-list'>
								<ul>
									<li>Adv. Software Application</li>
									<li>Front End Web Design</li>
									<li>Philosophical Aesthetics</li>
									<li>Life Drawing</li>
								</ul>
								<ul>
									<li>Art + Design Adv. Project</li>
									<li>Observational Drawing</li>
									<li>Professional Studies</li>
									<li>3D Animation</li>
								</ul>
							</div>
						<p><strong>HNC Computer Arts + Design</strong><br />Units studied during this course</p>
							<div className='split-list'>
								<ul>
									<li>Art + Design: Context/Projects</li>
									<li>Animation: An Introduction</li>
									<li>Creative Industries</li>
									<li>Theory + Practice</li>
								</ul>
								<ul>
									<li>Art + Design: Technologies</li>
									<li>Compositing + Motion Graphics</li>
									<li>Developmental Drawing</li>
									<li>Digital Imaging</li>
								</ul>
							</div>
					</div>
					<div className='cv-block'>
						<h4>Experience</h4>
						<p><strong>AND Digital<span className='pipe'>|</span>Associate Product Developer<br />Oct '22 - Present</strong><br />
						My time so far as a developer at AND Digital has helped me to further reinforce my skills and abilities in code planning, writing, reviewing and testing, as well as allowing several opportunities to work with leading teams of developers and business analysts.<br /><br />

						I have also lead two teams working on internal projects - one a straightforward archiving software with junior members of staff, and the other an experimental mobile app making use of modern computer vision technologies and various API connections, featuring a range of junior through to senior staff.<br /><br />

						My time at AND Digital has also seen me leading tech decisions and solutions for a charity, aimed at providing value to the staff through automation and process improvements, as well as reducing the increasing volume of work each volunteer has to process while improving charity-to-volunteer communication times.<br /><br />

						Lastly, I have provided much-needed technical solutions to the UK's leading electrical wholesale company CEF. I created these using ReactJS in combination with Atlassian Confluence to automate and reduce the burden on staff members assigned to manage their internal documentation. I then demoed and taught their tech team how to safely implement, deploy and amend these tools so that they would be in full control of the solution I supplied them. 
						</p>
						<p><strong>BiP Solutions Ltd<span className='pipe'>|</span>Front End Web Developer<br />Aug '21 - Oct '22</strong><br />
						Working at BiP Solutions helped me expand on my Front End development skills. It allowed me to hone my ability to refine and test the code I write, as well as showing me how to work as part of a team of developers based in an Agile development environment.<br /><br />

						My general day-to-day work within the company saw me maintain a large portfolio of the company's websites, responding to internal bug reports and changing request tickets, and working with the marketing team to help continually improve the SEO and discoverability of the company's main websites.<br /><br />

						I was also required to work closely with Project Managers and Product Owners to create custom functionality and features that the business requested. With these requests, I developed custom Javascript and/or PHP modules/plugins to fit the outline's requests. Once completed, I then documented the work and managed version control for the custom modules/plugins, allowing ease of use, understanding, and implementation of the new code for the business' other developers.
						</p>
						<p><strong>Exacta Print Ltd<span className='pipe'>|</span>Web + Graphic Designer<br />Feb '17 - Aug '21</strong><br />
						My role at Exacta Print was a busy and varied one. It predominantly consisted of creating, launching and managing websites from the ground up for small to medium businesses.<br /><br />
						
						I gained a wealth of experience within this role, especially in both Front End and Back End development areas. I played an integral part in modernising Exacta Print's web department/web workflow and managed to reduce outgoings substantially for several web-based services, both b2b and customer-facing. I also worked on graphic and print design jobs for customers looking for artwork for their print.<br /><br />
						
						In addition to the experience gained with commercial and customer facing web services and projects, I gained solid experience working within a fast-paced creative environment, managing unexpected jobs and improving workflow and web management processes.
						</p> 
						<p><strong>Freelance<span className='pipe'>|</span>Graphic Designer<br />Jan '16 - Feb '17</strong><br />
						Designing solutions for clients while adhering to mutually developed briefs. Projects included print and digital materials, web design/creation and identity/branding creation and development. Through this role, I gained a great deal of experience with face-to-face client relationships, as well as improving on various other skills such as problem solving, critical thinking and negotiation skills.</p>
						<p><strong>Royal Mail<span className='pipe'>|</span>Postman<br />Feb '12 - Feb '17</strong><br />
						I worked as part of a larger team to organise and deliver mail throughout Glasgow city centre. During my time with the Royal Mail, I developed strong timekeeping and teamwork skills as well as learning to be adaptable and flexible in my workflow to help meet changing conditions.</p>
					</div>
				</article>
			</section>
		</React.Fragment>
	);
}

export default About;